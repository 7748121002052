import './src/scss/theme.scss'
import('../../src/js/app')

$( document ).ready(function() {
    const smartEaseElements = document.querySelectorAll('.smart-ease');

    const minY = 0;

    function updateElementPosition(element) {
        const scrollY = window.scrollY;

        const maxY = parseInt(element.dataset.maxY);

        const newY = Math.max(minY, maxY - scrollY);

        element.style.transform = `translateY(${newY}px)`;

    }

    function updatePositions() {
        smartEaseElements.forEach(updateElementPosition);
    }

    updatePositions();

    window.addEventListener('scroll', updatePositions);
});

